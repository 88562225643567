import React from "react";
// import BG from "../../images/NewImages/Background.svg";
import hqlogo from "../../images/Home/mainqubelogo.png"

import ourmissionicon from "../../images/CorporateFacelift/icons/our-mission-icon.png"
import ourvaluesicon from "../../images/CorporateFacelift/icons/our-values-icon.png"

function NewMission() {
    return (
        <>
            <section class="new-mission-showcase">
                <div className="mission-img-container d-flex w-100 h-100 flex-column justify-content-center align-items-center">
                    <div className="new-mission-center-img w-100">
                        <img src={hqlogo} alt="HomeQube" />
                    </div>
                </div>
                <div class="new-mission-bottom w-100 d-flex flex-column justify-content-center align-items-center">
                    <div className="d-flex flex-column w-100 mb-5">
                        <div class="new-mission-content-container-left">
                            <div className="w-100 mb-4 d-flex flex-row gap-5 justify-content-start align-items-center">
                                <div className="our-mission-icon">
                                    <img src={ourmissionicon} alt="Our Mission Icon" />
                                </div>
                                <h2 class="new-mission-left-title">
                                    Our Mission
                                </h2>
                            </div>
                            <p className="new-mission-left-paragraph">
                                {/* To disrupt worldwide home scarcity using a Built Environment Engine through Regeneration and Decentralization */}
                                To disrupt home scarcity through Regeneration and Decentralization solving the housing backlogs of those who need it. 
                            </p>
                        </div>
                    </div>
                    <div className="d-flex flex-column w-100">
                        <div class="new-mission-content-container-left">
                            <div className="w-100 mb-4 d-flex flex-row gap-5 justify-content-start align-items-center">
                                <div className="our-mission-icon">
                                    <img src={ourvaluesicon} alt="Our Values Icon" />
                                </div>
                                <h2 class="new-mission-left-title">
                                    Our Values
                                </h2>
                            </div>
                            <p className="new-mission-left-paragraph">
                                Love for God and Neighbour
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default NewMission;
